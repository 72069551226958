<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <h2 class="text-center">JOIN US!</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
            <p class="text-center">
            <em>
              We have no paid JRF positions at this time or short term trainee postions untill July 2025. Kindly check back later.
            </em>
          </p>
        </b-col>
      </b-row>
     <!-- <b-row>
        <b-col>
          <h4>Project-based trainees -</h4>
          <p>
            Project-based trainees can either join for a period of six months or
            1 year. During your initial few weeks, we will mutually assess fit
            for different projects in the lab and assign the trainee to a
            suitable project based on their interest. Feel free to reach out to
            our current Project trainees listed under the Team page to find out
            about their experience.
          </p>
          <p>
            If interested, go through
            <a
              href="https://www.ccmb.res.in/Academics/Training-Programs?rr=Project-based-Research-Training-Programme"
              target="_blank"
              >this link</a
            >
            to understand the program better, and then write to Ishwariya to
            schedule an initial screening call. If we both agree that this could
            work, a consent letter will be provided for you to formally apply.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h4>Dissertation Research Training program -</h4>
          <p>
            CCMB invites applications from candidates pursuing M.Sc / M.Pharm/
            M.Tech/ B.Pharm/ B.Tech in any branch of Life sciences to undertake
            research training towards partial fulfillment of their degree.
            Candidates can either join for a period of six months or 1 year.
            During your initial few weeks, we will mutually assess fit for
            different projects in the lab and assign the trainee to a suitable
            project based on their interest.
          </p>
          <p>
            If interested, go through
            <a
              href="https://www.ccmb.res.in/Academics/Training-Programs?rr=Dissertation-Research-Training-Program"
              target="_blank"
              >this link</a
            >
            to understand the program better, and then write to Ishwariya to
            schedule an initial screening call. If we both agree that this could
            work, a consent letter will be provided for you to formally apply.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h4>Post-doctoral fellows-</h4>
          <p>
            We are always looking for motivated post-doctoral fellows to join
            our crew. If you are interested in our work, please write to
            Ishwariya to discuss the possibility of writing post-doctoral
            fellowships together to fund your research and time in the lab.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h4>Graduate students-</h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>
            We take in rotation students who have been selected via the CCMB
            Ph.D. admissions program.
            <a
              href="https://www.ccmb.res.in/Academics/PhD-Program"
              target="_blank"
              >Click here</a
            >
            to learn more about the program.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <h4>Summer trainees-</h4>
          <p>
            Read more about the Summer Training Program at CCMB
            <a
              href="https://www.ccmb.res.in/Academics/Training-Programs?rr=Summer-Training-Program"
              >here</a
            >. If you wish to participate, please apply formally through the
            portal and mention your research interests in your statement of
            purpose so you can be matched accordingly.
          </p>
        </b-col>
      </b-row>
       <b-row>
        <h4>Guest workers-</h4>
        <p>
          This is an unpaid position that is generally suited for students
          looking for a quick pitstop en route to grad school or looking to
          spend their summers more productively. This is also suited for
          students trained in a particular field but are interested in switching
          gears to Neuroscience/Genomics and would like to gain some research
          experience in a Neuroscience research lab. The minimum duration is 2
          months and the maximum is three months. No accommodation will be
          provided at CCMB. Guest workers will not get access to the Animal/Cell
          culture facility and will primarily observe lab members performing
          these experiments. They will however be trained in Molecular Biology
          techniques, preparation of next-gen sequencing libraries, participate
          in lab meetings and journal clubs, and gain an overall experience of
          what it is like to work in a research lab. Feel free to reach out to
          our current Guest workers listed under the Team page to find out about
          their experience.
        </p>
      </b-row> -->
    </b-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
h2,
h4 {
  font-weight: bold;
  color: #346225 !important;
}
p {
  color: #346225 !important;
  font-size: 19px;
}
</style>
